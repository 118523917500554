export const setSessionId = (sessionId)=>({
    type : 'SET_SESSION_ID',
    payload : sessionId
})
export const clearSessionId = () => ({
    type: 'CLEAR_SESSION_ID',
});
export const setLoginUserId = (userId) => ({
    type: 'SET_USER_ID',
    payload: userId,  // Accept userId and set it as payload
});
