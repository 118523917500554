import React from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

const index = () => {
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox py={3}>
      
    
      <MDBox>
        
      </MDBox>
    </MDBox>
    
  </DashboardLayout>
  )
}

export default index