import React from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useSelector } from 'react-redux'

const candidate = () => {
  const sessionId = useSelector((state) => state.session.sessionId);

  console.log("Session ID:", sessionId);

  console.log("test")
  return (
    <>
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
         
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
     </>
  )
}

export default candidate