import React from "react";
import MDBox from "components/MDBox";
import Tree from "react-d3-tree";
import { IoIosCloseCircle } from "react-icons/io";
import "./tree.css";
import step from "assets/theme/components/stepper/step";

// Define the NodeLabel component to customize the node label appearance

// Define the organizational chart data
const myTreeData = [
  {
    name: "Gaurang Torvekar",
   
    children: [
      {
        name: "Avadhoot",
        
        children: [
          { name: "Richard" },
          {
            name: "Constantine",
            children: [{ name: "Mia" }],
          },
          { name: "Daniel" },
        ],
      },
      { name: "Mia",
        children: [
          { name: "Richard" },
          {
            name: "Constantine",
            children: [{ name: "Mia" }],
          },
          { name: "Daniel" },
        ],
       },
     
    ],
  },
];

// Define the rectangle shape for the nodes


// OrgChartTree component
function OrgChartTree({ isOpen, isClose }) {
  if (!isOpen) return null;

  return (
    <div className="z-10000">
      <MDBox py={3} className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-10">
          <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-lg">
            <div className="p-4 sm:p-6">
              <div className="flex justify-end items-center pb-4 border-gray-200">
                <button className="text-red-500 hover:text-gray-600 text-3xl" onClick={isClose}>
                  <IoIosCloseCircle />
                </button>
              </div>
              <div id="treeWrapper" style={{ width: "100%", height: "50vh" }}>
                <Tree
                  data={myTreeData}
                  pathFunc="step"
                  orientation="vertical"
                  nodeSvgShape={{
                    shape: 'rect',
                    
                  }}
                  rootNodeClassName="node__root"
                  branchNodeClassName="node__branch"
                  leafNodeClassName="node__leaf"
                  separation={{ siblings: 1, nonSiblings: 2 }}
                  nodeLabelComponent={{
                    render: <div>Label</div>,
                    foreignObjectWrapper: {
                      y: -24,
                      x: -12,
                      width: 100,
                      height: 100,
                    },
                    
                  }}
                  // renderCustomNodeElement={(rd3tProps) => (
                  //   <CustomNode {...rd3tProps} />
                  // )}
                />
              </div>
            </div>
          </div>
        </div>
      </MDBox>
    </div>
  );
}

export default OrgChartTree;
