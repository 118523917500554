import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Tree from "react-d3-tree";
import { useSelector } from 'react-redux';
import axios from 'axios';
import userSessionHandle from "../../userSessionHandle"




const jobListing = () => {
  const [treeData, settreedata] = useState([])
  userSessionHandle()
  const sessionId = useSelector((state) => state.session.sessionId);
  const user_id = useSelector((state) => state.session.userId.value);
console.log(user_id);


  const getTreeData = async() =>{
    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/Treeview.php";
    const payload = {
      session_id: sessionId,
      parent_id : user_id,
    };
    
      try {
        const response = await axios({
          method: "get",
          url: apiUrl,
          params: payload,
        })
        console.log(response.data);
        settreedata(response.data)
        console.log();
        
        
    }catch(error){
console.error(error)
    }

  }
  useEffect(()=>{
    getTreeData()
  },[sessionId])

  
  

  return (
    <>
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
       <MDBox py={3} className="fixed inset-0 z-10 w-screen overflow-y-auto">
       
              <div id="treeWrapper" style={{ width: "750%", height: "100vh" }}>
              {treeData.length > 0 ? (
              <Tree
                data={treeData} // Pass the API response directly to the Tree component
                pathFunc="step"
                orientation="vertical"
                nodeSvgShape={{
                  shape: 'rect',
                }}
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
              />
            ) : (
              <p>Loading tree data...</p> // Loading message while data is being fetched
            )}
              </div>
           
      </MDBox>
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
     </>
  )
}

export default jobListing