import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useSessionCheck = () => {
    const sessionId = useSelector((state) => state.session.sessionId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionId) {
      alert("Your login session has expired. Redirecting to login page.");
      navigate("/authentication/sign-in"); // Redirect to login page
    }
  }, [sessionId, navigate]);
};

export default useSessionCheck;