const initialState = {
    sessionId : null,
    userId: null,
}

const sessionReducer = (state = initialState,action )=>{
    switch (action.type) {
        case 'SET_SESSION_ID':
            return {
                ...state,
                sessionId: action.payload,
            };
            case 'SET_USER_ID': // New case for setting userId
            return {
                ...state,
                userId: action.payload, // Set userId from action payload
            };
        case 'CLEAR_SESSION_ID':
            return {
                ...state,
                sessionId: null,
            };
        default:
            return state;
    }
}
export default sessionReducer;