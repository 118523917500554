/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts


import Dashboard from "layouts/dashboard";
import Referrals from "layouts/referrals";
import JobApplication from "layouts/JobApplication";
import Candidate from "layouts/candidate";
import User from "layouts/user";
import Payments from "layouts/payments";
import Category from "layouts/category";
import JobListing from "layouts/jobListing";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import DetailView from "layouts/JobApplication/detailveiw";
import SignUp from "layouts/authentication/sign-up";
import AddJobApplication from "layouts/JobApplication/addJobApplication";
import TreeView from "layouts/Treeview";
import AddReferrals from "layouts/referrals/addReferal";

import { FaHandshakeAngle } from "react-icons/fa6";
import { FaFilePen } from "react-icons/fa6";
import { MdManageAccounts } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";
import { MdOutlinePayments } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import { BiCategory } from "react-icons/bi";

// @mui icons
import Icon from "@mui/material/Icon";
import { GiFruitTree } from "react-icons/gi";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Referrals",
    key: "Referrals",
    icon: (
      <Icon fontSize="small">
        <FaHandshakeAngle />
      </Icon>
    ),
    route: "/referrals",
    component: <Referrals />,
  },
  {
    // type: "collapse",
    name: "Add Referrals",
    key: "addReferrals",
    // icon: <Icon fontSize="small"><FaHandshakeAngle /></Icon>,
    route: "/addReferrals",
    component: <AddReferrals />,
  },
  {
    type: "collapse",
    name: "Job Application",
    key: "jobapplication",
    icon: (
      <Icon fontSize="small">
        <FaFilePen />
      </Icon>
    ),
    route: "/jobapplication",
    component: <JobApplication />,
  },
  {
    type: "collapse",
    name: "Candidate",
    key: "candidate",
    icon: (
      <Icon fontSize="small">
        <MdManageAccounts />
      </Icon>
    ),
    route: "/candidate",
    component: <Candidate />,
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: (
      <Icon fontSize="small">
        <VscAccount />
      </Icon>
    ),
    route: "/user",
    component: <User />,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: (
      <Icon fontSize="small">
        <MdOutlinePayments />
      </Icon>
    ),
    route: "/payments",
    component: <Payments />,
  },
  {
    type: "collapse",
    name: "Job Listing",
    key: "JobListing",
    icon: (
      <Icon fontSize="small">
        <FaListCheck />
      </Icon>
    ),
    route: "/jobListing",
    component: <JobListing />,
  },
  {
    type: "collapse",
    name: "Category",
    key: "Category",
    icon: (
      <Icon fontSize="small">
        <BiCategory />
      </Icon>
    ),
    route: "/category",
    component: <Category />,
  },

  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },

  {
    // type: "collapse",
    name: "Detail View",
    key: "Detail View",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/jobapplication/detailView",
    component: <DetailView />,
  },
  {
    // type: "collapse",
    name: "Add Job Application",
    key: "Add Job Application",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/jobapplication/addJobApplication",
    component: <AddJobApplication />,
  },
  {
    type: "collapse",
    name: "Tree view",
    key: "Tree view",
    icon: (
      <Icon fontSize="small">
        <GiFruitTree />
      </Icon>
    ),
    route: "/treeview",
    component: <TreeView />,
  },
];

export default routes;
